import { Pipe, PipeTransform } from '@angular/core';
import { TermDict, TermService } from './term.service';

@Pipe({
    name: 'term',
})
export class TermPipe implements PipeTransform {
    constructor(private termSe: TermService) {}

    /** Трансформирует строку в строку из словаря или второго параметра
     * @param code - строка для перевода
     * @param override - строка для замены или объект {"term": "значение"}.
     * Если замена не находится, возвращает code
     */
    transform(code: string, override?: TermDict | string) {
        return this.termSe.getTerm(code, override) || code;
    }
}

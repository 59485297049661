import { TransitReportError } from '../srv.types';

/** Переводит ошибку TransitReportError в строку */
export function convertErrorToString(error: TransitReportError): string {
    let errorMessage = 'Ошибка выполнения';
    if (typeof error === 'string') {
        errorMessage = error;
    } else if (error && typeof error === 'object') {
        if (error.message) {
            if (typeof error.message === 'string') {
                errorMessage = error.message;
            } else if (error.message instanceof Array) {
                errorMessage = error.message.reduce(
                    (res, current, index) => res + ' ' + (index + 1).toString() + '. ' + current,
                    '',
                );
            }
        }
    }
    return errorMessage;
}

import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-crypto-certificates',
    templateUrl: './certificates.html',
})
export class CryptoCertificatesComponent {
    certificates: any[];

    constructor(private modal: ModalController) {}

    dismiss() {
        this.modal.dismiss();
    }

    selectCertificate(certificate) {
        this.modal.dismiss(certificate);
    }
}

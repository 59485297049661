export const TILE_SERVER_DEFAULT = 'map.asu.big3.ru';

export const COMMON_OPTIONS = {
    maxNativeZoom: 18,
    maxZoom: 21,
};

export const LAYERS = {
    GRID: {
        key: 'GRID',
        type: 'grid',
        title: '<span class="icon icon_border-outer"></span> Сетка',
        options: {
            isCoordShown: true,
            step: 10,
            coordStyle: 'MinDec',
            // coordTemplates: 10,
            lineStyle: {
                stroke: true,
                color: '#111',
                opacity: 0.7,
                weight: 1,
            },
            // redraw: 'move'
        },
    },
    GRID2: {
        key: 'GRID2',
        type: 'grid',
        title: '<span class="icon icon_border-inner"></span> Сетка',
        options: {
            coordStyle: 'DMS',
            // coordTemplates: 10,
            // maxStepsCount: 200,
            lineStyle: {
                stroke: true,
                color: '#000',
                opacity: 0.4,
                weight: 1,
            },
            // redraw: 'move'
        },
    },
    GRID10: {
        key: 'GRID10',
        type: 'grid',
        title: '<span class="icon icon_border-inner"></span> Сетка с метками',
        options: {
            coordStyle: 'DMS',
            isCoordShown: true,
            step: 1,
            lineStyle: {
                stroke: true,
                color: '#fff',
                opacity: 0.5,
                weight: 3,
            },
            // redraw: 'move'
        },
    },
    EDITABLE: {
        key: 'EDITABLE',
        title: '<span class="icon icon_map2"></span> Редактируемая карта',
        url: '/svc/map/tiles/editable/{z}/{x}/{y}.png',
        isEditable: true,
        options: { ...COMMON_OPTIONS, className: 'nc-maplayer_editable' },
    },
    OPENSTREET: {
        key: 'OPENSTREET',
        title: '<span class="icon icon_map2"></span> OpenStreetMap',
        url: `https://{s}.${TILE_SERVER_DEFAULT}/street/{z}/{x}/{y}.png`,
        options: COMMON_OPTIONS,
    },
    BLACK_WHITE: {
        key: 'BLACK_WHITE',
        title: '<span class="asuicon asuicon_blackandwhite"></span> Черно-белая OpenStreetMap',
        url: `https://{s}.${TILE_SERVER_DEFAULT}/bw/{z}/{x}/{y}.png`,
        options: COMMON_OPTIONS,
    },
    LIGHT: {
        key: 'LIGHT',
        title: '<span class="asuicon asuicon_lighten"></span> Светлая',
        url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
        options: COMMON_OPTIONS,
    },
    GRAY: {
        key: 'GRAY',
        title: '<span class="asuicon asuicon_grey"></span> Серая',
        url: 'https://korona.geog.uni-heidelberg.de/tiles/roadsg/x={x}&y={y}&z={z}',
        options: COMMON_OPTIONS,
    },
    DARK: {
        key: 'DARK',
        title: '<span class="asuicon asuicon_dark"></span> Тёмная',
        url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png',
        options: COMMON_OPTIONS,
    },
    TONER: {
        key: 'TONER',
        title: '<span class="asuicon asuicon_toner"></span> Тонер',
        url: 'http://{s}.tile.stamen.com/toner/{z}/{x}/{y}.png',
        options: COMMON_OPTIONS,
    },
    TONERX2: {
        key: 'TONERX2',
        title: '<span class="asuicon asuicon_toner"></span> Тонер 2',
        url: 'http://{s}.tile.stamen.com/toner/{z}/{x}/{y}@2x.png',
        options: COMMON_OPTIONS,
    },
    GPS: {
        key: 'GPS',
        title: '<span class="icon icon_radar"></span> GPS треки',
        url: 'https://{s}.gps-tile.openstreetmap.org/lines/{z}/{x}/{y}.png',
        options: COMMON_OPTIONS,
    },
    YANDEX: {
        key: 'YANDEX',
        title: 'Яндекс',
        url: 'https://vec02.maps.yandex.net/tiles?l=map&v=4.40&x={x}&y={y}&z={z}',
        options: COMMON_OPTIONS,
    },
    TRAFFIC: {
        key: 'TRAFFIC',
        title: '<span class="icon icon_traffic-lights"></span> Пробки',
        url: 'https://3.traffic.maps.api.here.com/maptile/2.1/traffictile/newest/normal.day/{z}/{x}/{y}/256/png8?app_id=xWVIueSv6JL0aJ5xqTxb&token=djPZyynKsbTjIUDOBcHZ2g&lg=RUS',
        options: COMMON_OPTIONS,
    },
    RELIEF: {
        key: 'RELIEF',
        title: '<span class="icon icon_"></span> Рельеф',
        url: 'https://maps-for-free.com/layer/relief/z{z}/row{y}/{z}_{x}-{y}.jpg',
        options: COMMON_OPTIONS,
    },
    TWOGIS: {
        key: 'TWOGIS',
        title: '<span class="icon icon_map"></span> 2ГИС',
        url: 'http://tile0.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1',
        options: { ...COMMON_OPTIONS, attribution: '&copy; <a href="http://law.2gis.ru">2ГИС</a>' },
    },
    SATELLITE: {
        key: 'SATELLITE',
        title: '<span class="icon icon_satellite"></span> Спутник',
        url: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        options: {
            ...COMMON_OPTIONS,
            attribution:
                "Map: <a href='http://www.arcgis.com/home/item.html?id=c4ec722a1cd34cf0a23904aadf8923a0'>ArcGIS - World Physical Map</a>",
        },
    },
    LANDSCAPE: {
        key: 'LANDSCAPE',
        title: '<span class="icon icon_"></span> Ландшафт',
        url: 'http://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png',
        options: {
            ...COMMON_OPTIONS,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>',
        },
    },
    CADASTRE: {
        key: 'CADASTRE',
        type: 'wms',
        url: 'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreWMS/MapServer/export',
        title: '<span class="icon icon_compass"></span> Кадастровая карта',
        options: {
            layers: '1,2,3,4,5,6,7,9,10,11,12,13,14,15,16,18,19,20,23,24,25,26,27,28,29,32,33,34,36,37,38,39,40',
            format: 'PNG',
            transparent: true,
            zIndex: 20,
            dpi: '96',
            bboxSR: '102100',
            imageSR: '102100',
            f: 'image',
        },
    },
    ZONES: {
        key: 'ZONES',
        type: 'wms',
        url: 'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/ZONES/MapServer/export',
        title: '<span class="icon icon_compass"></span> ЗОУИТ ',
        options: {
            layers: 'show:1,2,3,4',
            format: 'PNG',
            transparent: true,
            zIndex: 20,
            dpi: '96',
            bboxSR: '102100',
            imageSR: '102100',
            size: '1024,1024',
            f: 'image',
            _ts: false,
        },
    },
    VOLGA: {
        key: 'VOLGA',
        url: 'https://map.b3asu.ru/volga/{z}/{x}/{y}.png',
        title: '<span class="icon icon_panorama"></span> Берега Волги',
        options: {
            minNativeZoom: 8,
            maxNativeZoom: 21,
            maxZoom: 21,
            attribution: '&copy; <a href="">Volga</a>',
        },
    },
};

export const LAYERS_SETS = {
    entry: { default: LAYERS.BLACK_WHITE },
    modal: {
        default: LAYERS.OPENSTREET,
        twoGIS: LAYERS.TWOGIS,
    },
    back: {
        // grid: LAYERS.GRID,
        default: LAYERS.OPENSTREET,
        openStreetMapBlackAndWhite: LAYERS.BLACK_WHITE,
        satellite: LAYERS.SATELLITE,
        twoGIS: LAYERS.TWOGIS,
        light: LAYERS.LIGHT,
        // gray: LAYERS.GRAY,
        dark: LAYERS.DARK,
        toner: LAYERS.TONER,
        traffic: LAYERS.TRAFFIC,
        // landscape: LAYERS.LANDSCAPE,
        // tonerx2: LAYERS.TONERX2,
        // yandex: LAYERS.YANDEX,
        // globe: LAYERS.GLOBE,
        // relief: LAYERS.RELIEF,
    },
    overlay: {
        grid_2: LAYERS.GRID2,
        grid_10: LAYERS.GRID10,
        volga: LAYERS.VOLGA,
        cadastre: LAYERS.CADASTRE,
        zones: LAYERS.ZONES,
        gps: LAYERS.GPS,
    },
    common: {},
};

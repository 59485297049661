import { Pipe, PipeTransform } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DctService } from './dct.service';
import { DctName } from '../srv.service';

@Pipe({
    name: 'dctEnt',
})
export class DctEntPipe implements PipeTransform {
    constructor(private dct: DctService) {}

    async transform(code: string, dctkey: DctName) {
        return await firstValueFrom(this.dct.getEntity$(dctkey, code));
    }
}

import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(private toast: ToastController) {}

    public showToast(header?: string, message = '', handler?) {
        return this.toast.create({
            header,
            message,
            color: 'danger',
            position: 'top',
            buttons: [
                {
                    text: 'Ясно',
                    role: 'cancel',
                    handler,
                },
            ],
        });
    }
}

import { Injectable, Inject } from '@angular/core';
import { getPlugin, CADES } from './crypto';
import { Observable, from, firstValueFrom } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ModalController, ToastController } from '@ionic/angular';
import { CryptoCertificatesComponent } from './certificates/certificates';
import { DOCUMENT } from '@angular/common';
import { SrvService } from '../../srv.service';
import { FileEntity } from '../ws-file.service';

declare const Buffer;

let _btoa;
if (typeof window !== 'undefined' && typeof window.btoa === 'function') {
    _btoa = window.btoa;
} else
    _btoa =
        typeof Buffer === 'function'
            ? (str) => new Buffer(str).toString('base64')
            : () => {
                  throw new Error('Unsupported environment: `window.btoa` or `Buffer` should be supported.');
              };

@Injectable()
export class WsCryptoService {
    cadesplugin: any;
    ecpFile: FileEntity;

    constructor(
        private modal: ModalController,
        private toast: ToastController,
        private srv: SrvService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    async init() {
        try {
            this.cadesplugin = await getPlugin();
            console.warn('[CRYPTO] ->', this.cadesplugin);
            this.ecpFile = await this.getECPFile();
            console.warn('[CRYPTO] ecpFile ->', this.ecpFile);
        } catch (e) {
            console.warn('[!][CRYPTO] ->', e);
        }
    }

    public fetchCertList() {
        return new Promise((resolve, reject) => {
            let isPluginLoaded = false;
            let isPluginWorked = false;
            let cadesplugin = this.cadesplugin;
            if (!cadesplugin) return reject('Плагин для работы с ЭЦП не инициализирован');
            let isPluginEnabled = true;

            cadesplugin.async_spawn(
                function* (args) {
                    try {
                        const oAbout = yield cadesplugin.CreateObjectAsync('CAdESCOM.About');
                        isPluginLoaded = true;
                        let CurrentPluginVersion = oAbout.PluginVersion;
                        if (typeof CurrentPluginVersion === 'undefined') {
                            CurrentPluginVersion = oAbout.Version;
                        }

                        const oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
                        isPluginWorked = true;
                        yield oStore.Open();
                        const CertificatesObj = yield oStore.Certificates;
                        const Count = yield CertificatesObj.Count;
                        const certSelectOption: {
                            value: string;
                            label: string;
                            statusNotes: string[];
                            statusColor: string;
                            certificate: any;
                        }[] = [];
                        if (Count === 0) {
                            throw new Error('Certificate not found: ');
                        } else {
                            for (let i = 1; i <= Count; i++) {
                                const cert = yield CertificatesObj.Item(i);
                                const privateKey = yield cert.HasPrivateKey();
                                const SubjectName = yield cert.SubjectName;
                                const SerialNumber = yield cert.SerialNumber;
                                let isValid = false;
                                try {
                                    const Validator = yield cert.IsValid();
                                    isValid = yield Validator.Result;
                                } catch (e) {
                                    console.error('[!] cert validation', e);
                                }
                                const certFromDate = yield new Date(cert.ValidFromDate);
                                const certTillDate = yield new Date(cert.ValidToDate);
                                const currentDate = new Date();
                                let statusNotes = [];
                                let statusColor;
                                if (currentDate < certFromDate) {
                                    statusNotes.push('Сертификат ещё не активен по сроку действия');
                                    statusColor = 'warning';
                                } else if (currentDate > certTillDate) {
                                    status = 'истёк срок действия; ';
                                    statusColor = 'danger';
                                }
                                if (!privateKey) {
                                    statusNotes.push('Нет привязки к закрытому ключу');
                                    statusColor = 'danger';
                                }
                                if (!isValid) {
                                    statusNotes.push('не пройдена проверка цепочки сертификатов');
                                    statusColor = 'danger';
                                } else {
                                    statusNotes.push('Действителен');
                                    statusColor = statusColor || 'primary';
                                }
                                const labelCert = SubjectName.split(',').find((item: string) => item.indexOf('CN=') + 1)
                                    ? SubjectName.split(',')
                                          .find((item: string) => item.indexOf('CN=') + 1)
                                          .split('=')[1]
                                    : SubjectName;
                                certSelectOption.push({
                                    certificate: cert,
                                    value: SubjectName + ', SN=' + SerialNumber,
                                    label: labelCert,
                                    statusNotes,
                                    statusColor,
                                });
                            }
                            args[0](certSelectOption);
                        }
                    } catch (err) {
                        console.error('[CRYPTO] Some error', err);
                        if (!(isPluginLoaded && isPluginEnabled)) {
                            err = `КриптоПро ЭЦП Browser plug-in не установлен.
                        Нажмите Ок для перехода на страницу "Проверка работы КриптоПро ЭЦП Browser plug-in"`;
                            if (window.confirm(err)) {
                                const a = document.createElement('a');
                                a.href =
                                    'https://www.cryptopro.ru/sites/default/files/products/cades/demopage/simple.html';
                                a.target = '_blank';
                                a.click();
                            }
                        } else if (!isPluginWorked) {
                            err = 'КриптоПро ЭЦП Browser plug-in не был разрешен.';
                        }
                        args[1](err);
                    }
                },
                resolve,
                reject,
            );
        });
    }

    private createSign(certSubject, dataToSign) {
        return new Promise((resolve, reject) => {
            let cadesplugin = this.cadesplugin;
            if (!cadesplugin) return reject('Плагин для работы с ЭЦП не инициализирован');
            cadesplugin.async_spawn(
                function* (args) {
                    try {
                        // const oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
                        // yield oStore.Open();
                        // console.log("[DEV] certificate name:", args[0].label);
                        // console.log("[DEV] Certificate", args[0].certificate);
                        const oCertificate = args[0].certificate;
                        const oSigner = yield cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
                        yield oSigner.propset_Certificate(oCertificate);
                        const oSignedData = yield cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData');

                        yield oSigner.propset_Options(CADES.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN);
                        yield oSignedData.propset_ContentEncoding(CADES.CADESCOM_BASE64_TO_BINARY);
                        // yield oSignedData.propset_Content(_btoa(unescape(encodeURIComponent(args[1]))));
                        yield oSignedData.propset_Content(args[1]);
                        const sSignedMessage = yield oSignedData.SignCades(oSigner, CADES.CADESCOM_CADES_BES, true);
                        // resolve(sSignedMessage);
                        // yield oStore.Close();
                        args[2]({ sign: sSignedMessage });
                    } catch (err) {
                        args[3]('Failed to create signature. Error: ' + cadesplugin.getLastError(err));
                        // reject('Не удалось создать подпись: ' + cadesplugin.getLastError(err));
                    }
                },
                certSubject,
                dataToSign,
                resolve,
                reject,
            );
        });
    }

    public async selectCertificate(): Promise<any> {
        try {
            const loader = await this.srv.loading.create({ message: 'Обработка...', cssClass: 'nci-loading' });
            loader.present();
            await this.init();
            let certificates = await this.fetchCertList();
            console.warn('[CRYPTO] certificates', certificates);
            const modal = await this.modal.create({
                component: CryptoCertificatesComponent,
                componentProps: { certificates },
                cssClass: 'nc-modal__large nc-modal_z-up',
            });
            await modal.present();
            loader.dismiss();
            const { data } = await modal.onDidDismiss();
            if (data && data.label) {
                console.debug('[CRYPTO] selected', data);
                return data;
            } else {
                const err = 'Сертификат для подписи не выбран';
                console.warn('[CRYPTO]', err);
                await this.toast
                    .create({
                        header: 'Выбор ЭЦП',
                        message: err,
                        duration: 3000,
                        color: 'warning',
                        position: 'top',
                        buttons: [
                            {
                                text: 'Ясно',
                                role: 'cancel',
                            },
                        ],
                    })
                    .then((t) => t.present());
            }
        } catch (e) {
            console.error('[!][CRYPTO]', e);
            const err = e.message || e.toString();
            return await this.toast
                .create({
                    header: 'Выбор ЭЦП',
                    message: 'Неудачно: ' + err,
                    // duration: 10000,
                    color: 'danger',
                    position: 'top',
                    buttons: [
                        {
                            icon: 'information-circle-outline',
                            text: 'Инструкции',
                            handler: () => {
                                this.document.defaultView.open(this.ecpFile.file, '_blank');
                            },
                        },
                        {
                            text: 'Ясно',
                            role: 'cancel',
                            handler: () => this.srv.toastPresented$.next(false),
                        },
                    ],
                })
                .then((t) => {
                    t.present();
                    this.srv.toastPresented$.next(true);
                })
                .then(() => {
                    throw new Error(e);
                });
        }
    }
    public async getSignForDataWithCertificate(dataToSign, certificate): Promise<string> {
        try {
            if (certificate) {
                console.debug('[CRYPTO][CERT]', certificate);
                const signed = (await this.createSign(certificate, dataToSign)) as any;
                console.debug('[CRYPTO] signed:', signed);
                return signed.sign as string;
            } else {
                const err = 'Сертификат для подписи выбран не был';
                console.warn('[CRYPTO]', err);
                await this.toast
                    .create({
                        header: 'Использование ЭЦП',
                        message: err,
                        // duration: 5000,
                        color: 'danger',
                        position: 'top',
                        buttons: [
                            {
                                text: 'Ясно',
                                role: 'cancel',
                                handler: () => this.srv.toastPresented$.next(false),
                            },
                        ],
                    })
                    .then((t) => {
                        t.present();
                        this.srv.toastPresented$.next(true);
                    })
                    .then(() => {
                        throw new Error(err);
                    });
            }
        } catch (e) {
            console.error('[!][CRYPTO]', e);
            const err = e.message || e.toString();
            await this.toast
                .create({
                    header: 'Использование ЭЦП',
                    message: 'Неудачно: ' + err,
                    // duration: 10000,
                    color: 'danger',
                    position: 'top',
                    buttons: [
                        {
                            icon: 'information-circle-outline',
                            text: 'Инструкции',
                            handler: () => {
                                this.document.defaultView.open(this.ecpFile.file, '_blank');
                            },
                        },
                        {
                            text: 'Ясно',
                            role: 'cancel',
                            handler: () => this.srv.toastPresented$.next(false),
                        },
                    ],
                })
                .then((t) => {
                    t.present();
                    this.srv.toastPresented$.next(true);
                })
                .then(() => {
                    throw new Error(e);
                });
        }
    }

    private async getECPFile(): Promise<any> {
        try {
            return await firstValueFrom(
                this.srv.fetchOne$('file_public', 'GIS_OPVK_EDC').pipe(
                    map((file) => (file && file.attributes ? file.attributes : null)),
                    catchError((error) => error),
                ),
            );
        } catch (e) {
            console.error('[!][CRYPTO] getECPFile', e);
        }
    }
}

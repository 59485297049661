import { NgModule, ModuleWithProviders } from '@angular/core';
import { DctPipe } from './dct.pipe';
import { DctAsyncPipe } from './dct-async.pipe';
import { DctService } from './dct.service';
import { DctEntPipe } from './dct-ent.pipe';

@NgModule({
    declarations: [DctPipe, DctAsyncPipe, DctEntPipe],
    exports: [DctPipe, DctAsyncPipe, DctEntPipe],
})
export class DctModule {
    static forRoot(): ModuleWithProviders<DctModule> {
        return {
            ngModule: DctModule,
            providers: [DctService],
        };
    }

    static forChild(): ModuleWithProviders<DctModule> {
        return { ngModule: DctModule };
    }
}

export const UI_SCOPE = {
    'ui.page.databoard': true,
    'ui.page.databoard.profile': true,
    'ui.page.databoard.profile.organization': true,
    'ui.page.databoard.selected_organization': true,
    'ui.page.databoard.licenses': true,
    'ui.page.databoard.self_licenses': true,
    'ui.page.databoard.wasteplaces': true,
    'ui.page.databoard.facilities': true,
    'ui.page.databoard.vehicles': true,
    'ui.page.databoard.contract_complex_service': true,
    'ui.page.databoard.contract_recycling': true,
    'ui.page.databoard.contract_transportation': true,
    'ui.page.databoard.contract_waste_generator': true,
    'ui.page.databoard.bid': true,
    'ui.page.databoard.bid_ws': true,

    'ui.page.dashboard': true,

    'ui.page.contactdetail': true,
    'ui.page.waste_handover': true,
    'ui.ent.waste_handover.can_create': true, // TODO временное разрешение пока не сделают ui в админке

    'ui.page.summary': true,
    'ui.page.summary.bystatuses.wastereport_operation': true,
    'ui.page.summary.bystatuses.wasteplace': true,
    'ui.page.summary.bystatuses.vehicle': true,
    'ui.page.summary.bystatuses.organization_info': true,
    'ui.page.summary.bystatuses.facility': true,
    'ui.page.summary.bystatuses.bid': true,
    'ui.page.summary.total.user': true,

    'ui.page.analytics': true,
    'ui.page.desk-fo': true,
    'ui.page.database': true,
    'ui.page.organizations': true,
    'ui.page.licenses': true,
    'ui.page.wasteplaces': true,
    'ui.page.wasteplaces.has_next_instance_verification': true,
    'ui.page.vehicles': true,
    'ui.page.facilities': true,
    'ui.page.facilities.has_next_instance_verification': true,
    'ui.page.reports': true,
    'ui.page.bids': true,
    'ui.page.bids.has_next_instance_verification': true,
    'ui.page.bids-ws': true,
    'ui.page.modeling': true,
    'ui.page.telemetry': true,
    'ui.page.waste-conflicts': true,
    'ui.page.orders-wastesource': true,
    'ui.page.orders-wastesource.has_next_instance_verification': true,
    'ui.page.orders-recycling': true,
    'ui.page.orders-recycling.has_next_instance_verification': true,
    'ui.page.orders-transportation': true,
    'ui.page.orders-transportation.has_next_instance_verification': true,
    'ui.page.datafiles': true,
    'ui.page.recycle-reports': true,
    'ui.page.driver-tasks': true,
    'ui.page.integration-1c': true,
    'ui.page.contracts-waste-generator': true,
    'ui.page.contracts-waste-generator.has_next_instance_verification': true,
    'ui.page.contracts-waste-generator.fo_branch_filter_presets': true,
    'ui.page.contracts-transportation': true,
    'ui.page.contracts-transportation.has_group_view': true,
    'ui.page.contracts-recycling': true,
    'ui.page.contracts-recycling.has_group_view': true,
    'ui.page.contracts-complex-service': true,
    'ui.page.contracts-complex-service.has_group_view': true,
    'ui.page.charges-transportation': true,
    'ui.page.charges-recycling': true,
    'ui.page.charges-complex-service': true,
    'ui.page.responds-transportation': true,
    'ui.page.responds-recycling': true,
    'ui.page.responds-complex-service': true,
    'ui.page.subcontracts-waste-generator': true,
    'ui.page.subcontracts-waste-generator.has_next_instance_verification': true,
    'ui.page.mrpoa': true,
    'ui.page.notifications': true,
    'ui.page.notifications-tasks': true,
    'ui.page.bids.timer_color_term': true,
    'ui.page.wasteplaces.checklist': true,
    'ui.page.invoices_wg': true,
    'ui.page.off-system-info': true,
    'ui.page.refinance_rate': true,
    'ui.page.claim_payment': true,
    'ui.page.contactdetail.is_fo': true,
    'ui.page.eosdo_integration': true,

    // возможность создания сущности
    'ui.ent.wasteplace.can_create': true,
    'ui.ent.vehicle.can_create': true,
    'ui.ent.facility.can_create': true,
    'ui.ent.bid.can_create': true,
    'ui.ent.order_waste_source_invoice.can_create': true,
    'ui.ent.report.can_create': true,
    'ui.ent.recycle_report.can_create': true,
    'ui.ent.contract_waste_generator.can_create': true,
    'ui.ent.contract_transportation.can_create': true,
    'ui.ent.contract_recycling.can_create': true,
    'ui.ent.contract_complex_service.can_create': true,
    'ui.ent.respond_complex_service.can_create': true,
    'ui.ent.respond_recycling.can_create': true,
    'ui.ent.respond_transportation.can_create': true,
    'ui.ent.subcontract_waste_generator.can_create': true,
    'ui.ent.waste_report.can_view': true,
    'ui.ent.erp_history.can_view': true,
    'ui.ent.timer_color_term.can_view': true,
    'ui.ent.support_ticket.can_view': true,
    'ui.ent.timer_color_sla.can_view': true,
    'ui.ent.refinance_rate.can_create': true,
    'ui.ent.contactdetail.can_create': true,
    'ui.page.contactdetails.has_group_view': true,
    'ui.ent.claim_waste_source.can_create': true,

    // клиент
    'ui.client.workspace-wa': true,
    'ui.client.agent-ma': false,
    'ui.ent.check.can_view': true,
    'ui.ent.claim_payment.can_create': true,
    'ui.ent.off_system_payment.can_view': true,
    'ui.page.distribution': true,
    'ui.ent.update_org_status_button.can_view': true,
    'ui.ent.update_egrul_button.can_view': true,
    'ui.ent.check_data_button.can_view': true,

    'ui.page.bids.multisign': true,
    'ui.page.bids-ws.multisign': true,
    'ui.page.contracts-waste-generator.multisign': true,
    'ui.page.facilities.multisign': true,
    'ui.page.orders-recycling.multisign': true,
    'ui.page.orders-transportation.multisign': true,
    'ui.page.orders-wastesource.multisign': true,
    'ui.page.recycle-reports.multisign': true,
    'ui.page.reports.multisign': true,
    'ui.page.responds-recycling.multisign': true,
    'ui.page.responds-complex-service.multisign': true,
    'ui.page.responds-transportation.multisign': true,
    'ui.page.subcontracts-waste-generator.multisign': true,
    'ui.page.vehicles.multisign': true,
    'ui.page.wasteplaces.multisign': true,
    'ui.ent.mrpoa.can_download': true,
    'ui.page.claim_payment.multisign': true,
    'ui.page.support_ticket': true,
    'ui.ent.support_ticket.can_create': true,
    'ui.page.support_ticket_technical': true,
    'ui.ent.support_ticket.fed_operator_subtype': true,
} as const;

export type ScopeType = keyof typeof UI_SCOPE;

export const UI_TERMS = [
    {
        slug: 'ui.page.dashboard.title',
        type: 'string',
    },
    {
        slug: 'ui.page.databoard.icon',
        type: 'string',
    },
    {
        slug: 'ui.page.databoard.title',
        type: 'string',
    },
    {
        slug: 'ui.page.databoard.profile.title',
        type: 'string',
    },
    {
        slug: 'ui.page.databoard.self_licenses.title',
        type: 'string',
    },
    {
        slug: 'ui.page.databoard.licenses.title',
        type: 'string',
    },
    {
        slug: 'ui.page.databoard.wasteplaces.title',
        type: 'string',
    },
    {
        slug: 'ui.page.databoard.facilities.title',
        type: 'string',
    },
    {
        slug: 'ui.page.databoard.vehicles.title',
        type: 'string',
    },
    {
        slug: 'ui.page.wasteplaces.title',
        type: 'string',
    },
    {
        slug: 'ui.page.wasteplaces.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.facilities.title',
        type: 'string',
    },
    {
        slug: 'ui.page.facilities.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.vehicles.title',
        type: 'string',
    },
    {
        slug: 'ui.page.vehicles.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.licenses.title',
        type: 'string',
    },
    {
        slug: 'ui.page.licenses.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.reports.title',
        type: 'string',
    },
    {
        slug: 'ui.page.reports.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.bids.title',
        type: 'string',
    },
    {
        slug: 'ui.page.bids.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.bids-ws.title',
        type: 'string',
    },
    {
        slug: 'ui.page.bids-ws.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.modeling.externalLinkUri',
        type: 'string',
    },
    {
        slug: 'ui.page.waste-conflicts.title',
        type: 'string',
    },
    {
        slug: 'ui.page.waste-conflicts.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.orders-wastesource.title',
        type: 'string',
    },
    {
        slug: 'ui.page.orders-wastesource.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.orders-recycling.title',
        type: 'string',
    },
    {
        slug: 'ui.page.orders-recycling.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.orders-transportation.title',
        type: 'string',
    },
    {
        slug: 'ui.page.orders-transportation.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.summary.bystatuses.wastereport_operation.title',
        type: 'string',
    },
    {
        slug: 'ui.page.summary.bystatuses.wasteplace.title',
        type: 'string',
    },
    {
        slug: 'ui.page.summary.bystatuses.vehicle.title',
        type: 'string',
    },
    {
        slug: 'ui.page.summary.bystatuses.organization_info.title',
        type: 'string',
    },
    {
        slug: 'ui.page.summary.bystatuses.facility.title',
        type: 'string',
    },
    {
        slug: 'ui.page.summary.bystatuses.bid.title',
        type: 'string',
    },
    {
        slug: 'ui.page.summary.total.user.title',
        type: 'string',
    },
    {
        slug: 'ui.page.driver-tasks.title',
        type: 'string',
    },
    {
        slug: 'ui.page.driver-tasks.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.analytics.icon',
        type: 'string',
    },
    {
        slug: 'ui.page.analytics.title',
        type: 'string',
    },
    {
        slug: 'ui.page.datafiles.icon',
        type: 'string',
    },
    {
        slug: 'ui.page.datafiles.title',
        type: 'string',
    },
    {
        slug: 'ui.page.contracts-transportation.icon',
        type: 'string',
    },
    {
        slug: 'ui.page.contracts-transportation.title',
        type: 'string',
    },
    {
        slug: 'ui.page.contracts-recycling.icon',
        type: 'string',
    },
    {
        slug: 'ui.page.contracts-recycling.title',
        type: 'string',
    },
    {
        slug: 'ui.page.contracts-waste-generator.icon',
        type: 'string',
    },
    {
        slug: 'ui.page.contracts-waste-generator.title',
        type: 'string',
    },
    {
        slug: 'ui.page.contracts-complex-service.icon',
        type: 'string',
    },
    {
        slug: 'ui.contracts-complex-service.title',
        type: 'string',
    },
    {
        slug: 'ui.page.charges-complex-service.title',
        type: 'string',
    },
    {
        slug: 'ui.page.charges-complex-service.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.charges-recycling.title',
        type: 'string',
    },
    {
        slug: 'ui.page.charges-recycling.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.charges-transportation.title',
        type: 'string',
    },
    {
        slug: 'ui.page.charges-transportation.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.responds-complex-service.title',
        type: 'string',
    },
    {
        slug: 'ui.page.responds-complex-service.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.responds-recycling.title',
        type: 'string',
    },
    {
        slug: 'ui.page.responds-recycling.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.responds-transportation.title',
        type: 'string',
    },
    {
        slug: 'ui.page.responds-transportation.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.subcontracts-waste-generator.icon',
        type: 'string',
    },
    {
        slug: 'ui.page.subcontracts-waste-generator.title',
        type: 'string',
    },
    {
        slug: 'ui.page.subcontracts-waste-generator.hides',
        type: 'enum',
    },
    {
        slug: 'ui.page.mproa.title',
        type: 'string',
    },
    {
        slug: 'ui.page.support_ticket.title',
        type: 'string',
    },
    {
        slug: 'ui.page.support_ticket.icon',
        type: 'string',
    },
    {
        slug: 'ui.page.invoices_wg.title',
        type: 'string',
    },
    {
        slug: 'ui.page.invoices_wg.icon',
        type: 'string',
    },
];

export const UI_VISUALS = {
    '#1': {
        hasAva: false,
        sideMenu: 'some',
        menuTheme: 'dark-theme',
    },
    '#2': {
        hasAva: false,
    },
    '#opvk': {
        asideWidth: '340px',
        hasSideBarHeader: true,
        hasSideMenu: true,
        hasSideBarBlock_roles: true,
        hasSideBarElement_ava: true,
        hasSideBarElement_menuLines: true,
        hasSideBarElement_menuIcons: true,
        hasUIElement_frames: true,
        testMode: true,
    },
};

export const UI_ENTRANCE = {
    '#1': {
        tabTitle: 'FGIS OPVK',
        hasHelpLinks: true,
        hasTerms: true,
        shortSystemName: 'ФГИС ОПВК',
        systemName: 'Федеральная государственная информационная система учета и контроля',
        email: 'support@gisopvk.ru',
        phone: '+7 (495) 710-76-51',
        background: '#2F80ED',
    },
    '#2': {
        tabTitle: 'FGIS OPVK',
        hasHelpLinks: true,
        hasTerms: true,
        shortSystemName: 'ФГИС ОПВК',
        systemName: 'Федеральная государственная информационная система учета и контроля',
        email: 'support@gisopvk.ru',
        phone: '+7 (495) 710-76-51',
        background: '#2F80ED',
    },
    '#opvk': {
        tabTitle: 'FGIS OPVK',
        hasHelpLinks: true,
        hasTerms: true,
        shortSystemName: 'ФГИС ОПВК',
        systemName: 'Федеральная государственная информационная система учета и контроля',
        email: 'support@gisopvk.ru',
        phone: '+7 (495) 710-76-51',
        background: '#2F80ED',
        headerTitle: 'Обращение с отходами <br>I и II классов опасности',
        hasLogoB3: true,
        hasLogoRosatom: true,
    },
    // для стэйдж полигона
    '#stage': {
        tabTitle: 'ТЕСТОВЫЙ ПОЛИГОН',
        hasHelpLinks: true,
        hasLogoRosatom: false,
        hasTerms: true,
        shortSystemName: 'ТЕСТОВЫЙ ПОЛИГОН',
        systemName: 'XXXXXXXXXXXXXXXX информационная система учета и контроля',
        email: 'support@XXXXXXX.ru',
        phone: '+7 (495) XXX-XX-XX',
        background: '#309999',
        headerTitle: 'Обращение с отходами <br>I и II классов опасности',
        hasLogoB3: false,
        testModeLink: 'https://gisopvk.ru/media/files/filepublic/1/7/4/174d143e11742807.руководство-пользователя.pdf',
        testModeText: 'ТЕСТ ТЕКСТОВОГО ОБЪЯВЛЕНИЯ',
    },
};

export const UI_CLIENT = {
    'ui.client.workspace-wa': true,
    'ui.client.agent-ma': false,
};

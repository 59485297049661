import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

export type AppConfig = {
    auth_type: 'esia/credentials' | any;
    version: string;
    front_design: VisualStyle;
    entrance: VisualEntranceStyle;
    tile_map_server: string;
    bid_not_regular_date_range: number;
    bid_not_regular_not_earlier: number;
    bid_regular_date_range: number;
    bid_regular_not_earlier: number;
};

export type BidDateConfig = {
    bid_not_regular_date_range: number;
    bid_not_regular_not_earlier: number;
    bid_regular_date_range: number;
    bid_regular_not_earlier: number;
};

export type VisualStyle = {
    hasAva: boolean;
    sideMenu: string;
    menuTheme: string;
    hasSideMenu: boolean;
    uiTheme: string;
    hasPermanentHeader: boolean;
    hasDominantColor: boolean;
    hasPermanentHeaderBlock_roles: boolean;
    paginatorKind: string;
    headerKind: string;
    hasUIElement_frames: boolean;
    asideWidth: string;
    hasRO_role: boolean;
};

export type VisualEntranceStyle = {
    tabTitle: string;
    authBlockInCenter: boolean;
    hasHelpLinks: boolean;
    hasTerms: boolean;
    shortSystemName: string;
    systemName: string;
    email: string;
    phone: string;
    background: string;
    headerTitle: string;
};

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    private _configuration: AppConfig = null;

    constructor(private http: HttpClient) {}

    public loadConfiguration(): Promise<any> {
        return firstValueFrom(this.http.get('/webapi/v1/env/'))
            .then((configuration: { data: AppConfig }) => {
                console.log('env', configuration);
                this._configuration = configuration.data;
                return configuration;
            })
            .catch((error: any) => {
                console.error(error);
            });
    }

    public get visualStyle(): VisualStyle {
        return this._configuration ? this._configuration.front_design : null;
    }
    public get visualEntranceStyle(): VisualEntranceStyle {
        return this._configuration ? this._configuration.entrance : null;
    }
    public get tileServer(): string {
        return this._configuration ? this._configuration.tile_map_server : null;
    }
    public get bidDateConfig(): BidDateConfig {
        return {
            bid_not_regular_date_range: this._configuration?.bid_not_regular_date_range,
            bid_not_regular_not_earlier: this._configuration?.bid_not_regular_not_earlier,
            bid_regular_date_range: this._configuration?.bid_regular_date_range,
            bid_regular_not_earlier: this._configuration?.bid_regular_not_earlier,
        };
    }
}
